import { Box, Button } from '@mui/material'
import { graphHooks } from '@tunasong/graph-lib/react'
import { useContentViews } from '@tunasong/plugin-lib'
import type { Entity, Persisted } from '@tunasong/schemas'
import type { FC } from 'react'
import invariant from 'tiny-invariant'

export interface ContentViewsProps {
  entity?: Persisted<Entity>
}

export const ContentViews: FC<ContentViewsProps> = props => {
  const { entity } = props
  const contentViews = useContentViews(entity)

  const currentView = entity?.options?.activeView
  const activeView = currentView ? currentView : contentViews[0]
  const updateEntity = graphHooks.useEntityUpdate<Entity>()
  const handleView = (view: string) => () => {
    invariant(entity, 'Entity must be defined')
    updateEntity(entity.id, { options: { ...entity.options, activeView: view } })
  }

  if (contentViews.length <= 1) {
    return null
  }

  return (
    <Box>
      {contentViews.map(view => (
        <Button
          tabIndex={-1}
          key={view}
          disabled={activeView === view}
          sx={{ cursor: 'pointer' }}
          onClick={handleView(view)}
        >
          {view}
        </Button>
      ))}
    </Box>
  )
}

export default ContentViews
