import { isTopLevelEntity } from '@tunasong/models'
import type { NavigationEntity } from '@tunasong/models'
import invariant from 'tiny-invariant'

/** Entities have landing pages */

const getEntityTopLevelPath = (entityId: string) => `/app/e/${entityId}`
const getEntityParentPath = (entity: NavigationEntity) => `/app/e/${entity.parentId}?ref=${entity.id}`

export const getEntityPath = ({ entity, layout }: { entity: NavigationEntity; layout?: string }) => {
  if (entity.id === 'ROOT') {
    return '/app'
  }
  invariant(entity.id, 'Entity must have an id')

  /**
   * @todo we should make the "top-level" / child links more explicit
   */
  const isTopLevel = isTopLevelEntity(entity) || Object.keys(entity).length === 1
  const querySep = isTopLevel ? '?' : '&'
  const base = isTopLevel ? getEntityTopLevelPath(entity.id) : getEntityParentPath(entity)
  return layout ? `${base}${querySep}layout=${layout}` : base
}

export const getEntityPathById = ({ entityId, layout }: { entityId: string; layout?: string }) => {
  if (entityId === 'ROOT') {
    return '/app'
  }
  const base = getEntityTopLevelPath(entityId)
  return layout ? `${base}?layout=${layout}` : base
}

export const parseEntityIdFromUrl = (path: string, validDomains: (string | RegExp)[]) => {
  try {
    const url = new URL(path)

    if (
      !validDomains.some(domain => {
        if (typeof domain === 'string') {
          return domain === url.host
        }
        return domain.test(url.host)
      })
    ) {
      return null
    }

    // example URL http://localhost:8000/app/e/4gudpwgkgbtiwh5zh2zqjiotr
    const parts = url.pathname.split('/')
    if (parts.length < 4 || parts[1] !== 'app' || parts[2] !== 'e') {
      return null
    }
    return parts[3]
  } catch {
    return null
  }
}
