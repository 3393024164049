/** Encode Metadata as stringified JSON */

export interface FieldMetadata {
  [key: string]: unknown
  label?: string
}

export const encodeMetadata = (metadata: FieldMetadata) => JSON.stringify(metadata)
export const decodeMetadata = (data?: string) => {
  if (!data) {
    return undefined
  }
  try {
    return JSON.parse(data) as FieldMetadata
  } catch {
    return { label: data } as FieldMetadata
  }
}
