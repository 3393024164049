import { Box, LinearProgress } from '@mui/material'
import { graphHooks } from '@tunasong/graph-lib/react'
import type { EdgeType } from '@tunasong/schemas'
import type { FC } from 'react'
import { EntityLink } from '../entity/entity-link.js'
import EmptyLinks from './empty-links.js'

export interface LinksProps {
  sourceId?: string
  targetId?: string
}

const emojis: Record<EdgeType, string> = {
  embeds: '📎',
  follows: '👣',
  likes: '👍',
  isRelatedTo: '🔗',
  // hasRecording is the same as isPerformanceOf, except that it is a relation from the recording to the performance
  hasRecording: '🎙️',
  linksTo: '🔗',
  isParentOf: '👶',
  mentions: '💬',
  comments: '💬',
  includes: '📦',
  calendar: '📅',
  member: '👥',
  hasImage: '🖼️',
}

const internalTargets = ['assistant']

export const Edges: FC<LinksProps> = ({ sourceId = '', targetId }) => {
  const { entity: source } = graphHooks.useEntity(sourceId)
  const { edges: rawLinks = [], isLoading } = graphHooks.useEdges({ sourceId, targetId })
  /** Filter away isParentOf the relation, and internal targets */
  const links = rawLinks.filter(link => link.relation !== 'isParentOf' && !internalTargets.includes(link.target))

  const ids = links
    .flatMap(backlink => [backlink.target, backlink.context?.id])
    .filter(id => id !== 'ROOT')
    .filter(Boolean)

  const { entities = [] } = graphHooks.useEntitiesById(ids)

  return (
    <Box>
      {isLoading ? <LinearProgress /> : null}
      {links.map((link, idx) => {
        const target = entities.find(e => e.id === link.target)
        const context = entities.find(e => e.id === link.context?.id)
        const titletext = link.relation + (context ? ` in ${context.name}` : '')

        return target ? (
          <Box key={idx} sx={{ display: 'grid', gridTemplateColumns: '3fr 1fr 3fr', columnGap: 1, py: 0.5 }}>
            {source ? <EntityLink entity={source} /> : null}
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Box title={titletext} sx={{ fontSize: 20 }}>
                {' '}
                {emojis[link.relation as EdgeType]}{' '}
              </Box>
            </Box>
            <EntityLink entity={target} />
          </Box>
        ) : null
      })}
      {links.length === 0 ? <EmptyLinks /> : null}
    </Box>
  )
}

export default Edges
