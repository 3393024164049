import type { CardProps, Theme } from '@mui/material'
import { Box, Card, CardActions, CardContent, CardHeader, colors, IconButton } from '@mui/material'
import { MoreVertIcon } from '@tunasong/icons'
import { capitalize, dayjs } from '@tunasong/models'
import { features, useThunkDispatch } from '@tunasong/redux'
import type { Entity, EntityOrElement } from '@tunasong/schemas'
import { entityTypeNames, isPersisted } from '@tunasong/schemas'
import classNames from 'classnames'
import type { JSX, MouseEvent } from 'react'
import React from 'react'
import { useDrop } from 'react-dnd'
import { useUIExtensions } from '../extensions.hook.js'
import { VBox } from '../layout/vertical-box.js'
import UserAvatar from '../profile/user-avatar.js'
import { makeStyles } from '../styles.js'
import { useCurrentUser } from '../user/current-user.js'
import { EntityIcon } from './entity-icon.js'
import ListItemSkeleton from './list-item-skeleton.js'

export interface EntityCardProps<TElement extends Entity = Entity> extends CardProps {
  /** @todo these actions should have a onClose handler */
  actions?: JSX.Element
  /** If not specified display a skeleton */
  entity?: TElement

  size?: 'small' | 'medium' | 'large'

  cardMedia?: JSX.Element
  backgroundImage?: string

  /** Show the entity header. @default true */
  showHeader?: boolean
  /** Show menu. @default true */
  showMenu?: boolean
  /** Show secondary text. @default true */
  showSecondary?: boolean
  /** Top action button */
  headerAction?: JSX.Element | null
}

const useStyles = makeStyles()((theme: Theme) => ({
  content: {
    flex: 1,
    cursor: 'pointer',
    '& :hover': {
      cursor: 'pointer',
    },
    maxHeight: 300,
  },

  dropTarget: {
    borderColor: colors.green[500],
    borderWidth: theme.spacing(),
    borderStyle: 'dashed',
  },
}))

export const EntityCard = (props: EntityCardProps) => {
  const {
    headerAction,
    cardMedia = null,
    showMenu = true,
    showHeader = true,
    showSecondary = true,
    actions,
    entity,
    backgroundImage: cardImage,
    children,
    ref,
    onClick,
    ...restProps
  } = props
  const { classes } = useStyles()
  const dispatch = useThunkDispatch()

  const uiExtensions = useUIExtensions()

  const parentId = entity?.id

  const dropChild = async (childId?: string) => {
    if (!(childId && parentId)) {
      return
    }
    dispatch(features.entities.thunks.addChild({ childId, parentId }))
  }

  const canDrop = (item: EntityOrElement) => Boolean(item?.id !== entity?.id)
  const me = useCurrentUser()
  const hasExternalOwner = isPersisted(entity) ? entity.userId !== me.userId : false

  /** @todo this should probably be only in the DND version */
  const [{ isHovering }, dropRef] = useDrop({
    accept: entityTypeNames as never,
    drop: item => {
      dropChild(item.id)
    },
    canDrop,
    collect: monitor => ({
      isHovering: canDrop(monitor.getItem()) && monitor.isOver({ shallow: true }),
    }),
  })

  const [menuRef, setMenuRef] = React.useState<null | HTMLElement>(null)
  const handleActions = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setMenuRef(ref => (ref === null ? event.currentTarget : null))
  }
  const handleCloseActions = () => {
    setMenuRef(null)
  }

  const handleContext = (ev: MouseEvent) => {
    ev.preventDefault()
  }

  if (!isPersisted(entity)) {
    return <ListItemSkeleton />
  }

  const hAction = headerAction ? (
    headerAction
  ) : headerAction !== null ? (
    <VBox sx={{ alignItems: 'center', pl: 1 }}>
      {hasExternalOwner && <UserAvatar userId={entity.userId} size="small" />}

      <IconButton
        sx={{
          cursor: 'pointer',
          transition: 'opacity 0.3s',
          opacity: 0,
          '&:hover': {
            opacity: 1,
          },
        }}
        color="inherit"
        aria-label="settings"
        onClick={handleActions}
      >
        <MoreVertIcon />
      </IconButton>
    </VBox>
  ) : null

  return (
    <Card
      sx={{
        cursor: 'pointer',
        position: 'relative',
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
      ref={ref}
      onContextMenu={handleContext}
      raised={false}
      elevation={0}
      variant="outlined"
      {...restProps}
    >
      {cardImage ? (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundImage: `url(${cardImage})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        />
      ) : null}
      {}
      {showHeader ? (
        <CardHeader
          sx={{
            width: '100%',
            zIndex: cardImage ? 1 : undefined,
            backgroundColor: theme =>
              cardImage ? `rgba(${theme.vars.palette.background.paperChannel} / 0.8)` : undefined,
          }}
          avatar={<EntityIcon entity={entity} size="large" />}
          action={hAction}
          title={entity.name}
          onClick={onClick}
          titleTypographyProps={{ variant: 'h6' }}
          subheader={showSecondary ? `${capitalize(entity.type)} - ${dayjs(entity.updatedAt).fromNow()}` : null}
        />
      ) : null}
      {cardMedia}

      {children && (
        <CardContent
          sx={{
            zIndex: cardImage ? 1 : undefined,
            backgroundColor: cardImage ? 'rgba(255, 255, 255, 0.8)' : undefined,
          }}
          className={classNames(classes.content, { [classes.dropTarget]: isHovering })}
          ref={dropRef as never}
          onClick={onClick}
        >
          {children}
        </CardContent>
      )}
      {actions && <CardActions>{actions}</CardActions>}

      {showMenu && menuRef && uiExtensions.entityList?.ItemMenu ? (
        <uiExtensions.entityList.ItemMenu entity={entity} onClose={handleCloseActions} anchorEl={menuRef} />
      ) : null}
    </Card>
  )
}

export default EntityCard
