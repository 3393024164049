import type { Breakpoint, DialogProps } from '@mui/material'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, LinearProgress } from '@mui/material'
import type { FC } from 'react'
import React from 'react'
import { DialogTitle } from './dialog-title.js'

export interface ConfirmDialogProps extends DialogProps {
  className?: string
  open: boolean
  title?: string
  /** Busy - disable all inputs / buttons and show a progress bar */
  busy?: boolean
  text: string
  maxWidth?: false | Breakpoint | undefined

  onCancel(ev: React.SyntheticEvent): void
  onConfirm(ev: React.SyntheticEvent): void
}

export const ConfirmDialog: FC<ConfirmDialogProps> = props => {
  const { children, text, busy = false, open, onConfirm, onCancel, title = 'Confirm', ...restProps } = props

  if (!open) {
    return null
  }
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClick={ev => ev.stopPropagation()}
      {...restProps}
    >
      <DialogTitle onClose={onCancel} id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
        {children}

        {busy ? <LinearProgress sx={{ mt: 2 }} /> : null}
      </DialogContent>
      <DialogActions>
        <Button disabled={busy} onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={busy} onClick={onConfirm} autoFocus={true}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
