import { useEffect, useRef } from 'react'
import { useHover } from '../hooks/hover.js'
import { graphHooks } from '@tunasong/graph-lib/react'

/** Pre-load the entity on hover */
export const usePreloadOnHover = ({
  entityId,
  containerEl,
  debounceMs = 300,
}: {
  entityId?: string
  containerEl: HTMLElement | null
  debounceMs?: number
}) => {
  const hover = useHover(containerEl)
  const { getEntityById } = graphHooks.useEntityGraph()

  const debounceRef = useRef<NodeJS.Timeout | number | null>(null)

  useEffect(() => {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current)
      debounceRef.current = null
    }

    // If we are not hovering over the element, we don't need to load the entity
    if (!(hover && entityId)) {
      return
    }
    debounceRef.current = setTimeout(() => {
      // We use getEntityById instead of prefetch because we want to load the entity to check for storageUrls
      getEntityById(entityId)
      debounceRef.current = null
    }, debounceMs)
  }, [hover, entityId, getEntityById, debounceMs])
}
