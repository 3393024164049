import { TabPanel, Tabs, TabsList } from '@mui/base'
import { Box } from '@mui/material'
import { logger } from '@tunasong/models'
import type { NavigationEntity } from '@tunasong/models'
import { useEffect, useRef, useState } from 'react'
import type { FC } from 'react'
import { EntityContent } from '../entity/entity-content.js'
import type { EntityContentProps } from '../entity/entity-content.js'
import { EntityIcon } from '../entity/entity-icon.js'
import { useStableRef } from '../hooks/stable-ref.js'
import { useNavigate, useNavigateToEntityStore } from '../navigation/navigate.js'
import type { NavigationMode } from '../navigation/navigate.js'
import { ContentGroupTab } from './content-group-tab.js'
import type { ContentGroupSpec } from './content-group.js'
import { VBox } from './vertical-box.js'
import { graphHooks } from '@tunasong/graph-lib/react'

export interface ContentGroupTabsSpec extends ContentGroupSpec {
  panelType: 'tab'
}

export type MinimalComponentProps = Pick<EntityContentProps, 'entity'>

export interface ContentGroupTabsProps<T extends MinimalComponentProps> {
  content: ContentGroupTabsSpec[]

  /** By default, when there is only one content item, do not show the tabs */
  alwaysShowTabs?: boolean

  // The component to render the content
  ContentComponent: FC<T>
  componentsProps: T

  onOpenContent?: (content: ContentGroupTabsSpec) => void
  onCloseContent?: (content: ContentGroupTabsSpec) => void
}

/** Sticky Content Dialog Group  */
export const ContentGroupTabs = <T extends MinimalComponentProps>(props: ContentGroupTabsProps<T>) => {
  const {
    content,
    alwaysShowTabs = false,
    onOpenContent,
    onCloseContent,
    ContentComponent = EntityContent,
    componentsProps,
  } = props

  const entityIds = content.map(content => content.entityId)
  const { entities } = graphHooks.useEntitiesById(entityIds)

  const byId = (id: string) => entities.find(entity => entity.id === id)
  const decoratedEntities = content
    .map(content => {
      const entity = byId(content.entityId)
      if (!entity) {
        return null
      }
      return {
        ...content,
        entity,
      }
    })
    .filter(Boolean)

  // Handle navigation to the current entity
  const routerNavigate = useNavigate()
  const { setCustomNavigateFn, clearCustomNavigateFn } = useNavigateToEntityStore()

  const onCloseContentRef = useStableRef(onCloseContent)
  // Avoid infinite recursion by using ref for onOpenEntity
  const onOpenContentRef = useStableRef(onOpenContent)

  useEffect(() => {
    setCustomNavigateFn((entity?: NavigationEntity, props?: { layout?: string; navigationMode?: NavigationMode }) => {
      if (!entity?.id) {
        return
      }
      logger.debug('Navigating to entity', { entity, props })
      // if (props?.navigationMode !== 'tab') {
      //   return routerNavigate(getEntityPath({ entity, layout: props?.layout }), {
      //     replace: false,
      //   })
      // }

      if (onOpenContentRef.current) {
        onOpenContentRef.current({
          entityId: entity.id,
          panelType: 'tab',
        })
      }
    })
    return () => {
      clearCustomNavigateFn()
    }
  }, [clearCustomNavigateFn, onOpenContentRef, routerNavigate, setCustomNavigateFn])

  const [tab, setTab] = useState<string | number | null>(entityIds[0])
  // Set the dialog tab to the last entity if it's not set
  const lastDialogLength = useRef(decoratedEntities.length)
  useEffect(() => {
    if (decoratedEntities.length === 0 || lastDialogLength.current === decoratedEntities.length) {
      return
    }
    lastDialogLength.current = decoratedEntities.length
    setTab(decoratedEntities?.[decoratedEntities.length - 1]?.entityId)
  }, [decoratedEntities, tab, lastDialogLength])

  const handleClose = (entityId: string) => {
    const entityContent = content.find(content => content.entityId === entityId)
    if (entityContent && onCloseContentRef.current) {
      onCloseContentRef.current(entityContent)
    }
  }

  if (!alwaysShowTabs && decoratedEntities.length <= 1) {
    const content = decoratedEntities[0]
    return <ContentComponent entity={content?.entity} {...componentsProps} />
  }

  return (
    <Tabs<typeof VBox>
      slots={{
        root: VBox,
      }}
      sx={{ flex: 1, overflow: 'hidden' }}
      value={tab ?? ''}
      orientation="horizontal"
      onChange={(_, val) => setTab(val)}
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabsList>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {decoratedEntities.map(content => (
              <ContentGroupTab
                key={content.entityId}
                label={content.entity?.name ?? content.entityId}
                value={content.entityId}
                startIcon={<EntityIcon entity={content.entity} />}
                onClose={() => handleClose(content.entityId)}
              />
            ))}
          </Box>
        </TabsList>
      </Box>
      {decoratedEntities
        .filter(c => c.entityId === tab)
        .map(content => (
          <TabPanel<typeof Box>
            slots={{ root: Box }}
            sx={{
              display: 'flex',
              flex: 1,
              overflow: 'hidden',
            }}
            key={content.entityId}
            value={content.entityId}
          >
            <ContentComponent entity={content.entity} {...componentsProps} />
          </TabPanel>
        ))}
    </Tabs>
  )
}
