import { Button, ListItemAvatar, ListItemText, MenuItem, MenuList } from '@mui/material'
import { graphHooks } from '@tunasong/graph-lib/react'
import { Share } from '@tunasong/icons'
import type { ACL, Entity, Persisted } from '@tunasong/schemas'
import type { FC } from 'react'
import { useCallback, useMemo, useState } from 'react'
import { UserAvatar } from '../profile/index.js'
import { useCurrentUser } from '../user/index.js'

export interface TransferEntityProps {
  entity?: Persisted<Entity>
  onTransferred?(): void
}

export const TransferEntity: FC<TransferEntityProps> = props => {
  const { entity, onTransferred } = props
  const acls: ACL[] = useMemo(() => entity?.acls ?? [], [entity])
  const userIds = useMemo(() => acls.map(acl => acl.principal), [acls])
  const { profiles } = graphHooks.useProfiles({ userIds, includeAssistant: false })
  const ownerProfile = useCurrentUser()

  const [transferEntityMutation, transferEntityStatus] = graphHooks.useEntityTransfer()

  const transferEntity = useCallback(
    (principal: string) => async () => {
      if (!entity) {
        return
      }
      await transferEntityMutation({ entity, toUserId: principal }).unwrap()
      setSelectedPrincipal(null)
      if (onTransferred) {
        onTransferred()
      }
    },
    [entity, onTransferred, transferEntityMutation]
  )

  const [selectedPrincipal, setSelectedPrincipal] = useState<string | null>(null)
  const handleSelect = useCallback((userId: string) => () => setSelectedPrincipal(userId), [])

  return (
    <>
      <MenuList>
        {acls
          .filter(acl => acl.permission === 'WRITE' && acl.principal !== ownerProfile.userId)
          .map((a, idx) => {
            const profile = profiles?.find(p => p.userId === a.principal)
            const id = profile ? profile.email : `Loading ${a.principal}...`
            return (
              <MenuItem key={idx} onClick={handleSelect(a.principal)} selected={selectedPrincipal === a.principal}>
                <ListItemAvatar>
                  <UserAvatar aria-label="user" userId={profile?.userId} />
                </ListItemAvatar>
                <ListItemText primary={id} />
              </MenuItem>
            )
          })}
      </MenuList>
      {selectedPrincipal && (
        <Button
          startIcon={<Share />}
          disabled={transferEntityStatus.isLoading}
          onClick={transferEntity(selectedPrincipal)}
        >
          Transfer to {profiles?.find(p => p.userId === selectedPrincipal)?.email}
        </Button>
      )}
    </>
  )
}

export default TransferEntity
