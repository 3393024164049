import { Box, Drawer, IconButton } from '@mui/material'
import { Close } from '@tunasong/icons'
import type { NavigationEntity } from '@tunasong/models'
import { logger } from '@tunasong/models'
import type { TunaPlugin } from '@tunasong/plugin-lib'
import { useCallback, useEffect } from 'react'
import { EntityContent } from '../entity/entity-content.js'
import { useStableRef } from '../hooks/stable-ref.js'
import type { NavigationMode } from '../navigation/navigate.js'
import { useNavigateToEntityStore } from '../navigation/navigate.js'
import type { ContentGroupTabsSpec } from './content-group-tabs.js'
import { ContentGroupTabs } from './content-group-tabs.js'

export interface ContentGroupDrawerSpec {
  panelType: 'drawer'
  entityId: string
  contentViewName?: string
}

export interface ContentGroupDrawerProps {
  content: ContentGroupTabsSpec[]

  children?: React.ReactNode

  direction: 'horizontal' | 'vertical'

  plugins?: TunaPlugin[]

  onOpenContent: (content: ContentGroupTabsSpec) => void
  onCloseContent: (content: ContentGroupTabsSpec) => void
}

export const ContentGroupDrawer = (props: ContentGroupDrawerProps) => {
  const { children, content, onOpenContent, onCloseContent } = props

  const { setCustomNavigateFn, clearCustomNavigateFn } = useNavigateToEntityStore()

  const onCloseContentRef = useStableRef(onCloseContent)
  // Avoid infinite recursion by using ref for onOpenEntity
  const onOpenContentRef = useStableRef(onOpenContent)

  const navigateFn = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (entity?: NavigationEntity, props?: { layout?: string; navigationMode?: NavigationMode }) => {
      if (!entity?.id) {
        return
      }
      if (onOpenContentRef.current) {
        onOpenContentRef.current({
          entityId: entity.id,
          panelType: 'tab',
        })
      }
    },
    [onOpenContentRef]
  )
  /** @todo factor out the navigation handler */
  useEffect(() => {
    logger.debug('Setting custom navigate function')
    setCustomNavigateFn(navigateFn)
    return () => {
      clearCustomNavigateFn()
    }
  }, [clearCustomNavigateFn, navigateFn, setCustomNavigateFn])

  const drawerContent = content.filter(content => content.panelType === 'tab')
  const showDrawer = drawerContent.length > 0

  const handleCloseDrawer = () => {
    drawerContent.forEach(content => onCloseContentRef.current && onCloseContentRef.current(content))
  }

  return (
    <>
      {children}
      <Drawer anchor={'right'} open={showDrawer} onClose={handleCloseDrawer} variant="temporary">
        <Box sx={{ p: 1, maxWidth: 500 }}>
          <IconButton
            sx={{ position: 'absolute', right: theme => theme.spacing(), zIndex: 1 }}
            onClick={handleCloseDrawer}
          >
            <Close />
          </IconButton>
          <ContentGroupTabs
            content={drawerContent}
            onOpenContent={onOpenContentRef.current}
            onCloseContent={onCloseContentRef.current}
            ContentComponent={EntityContent}
            componentsProps={{}}
          />
        </Box>
      </Drawer>
    </>
  )
}

export default ContentGroupDrawer
