import { graphHooks } from '@tunasong/graph-lib/react'
import type { FC, MouseEvent } from 'react'
import { useNavigateToEntity } from '../navigation/navigate.js'
import type { EntityListItemProps } from './entity-list-item.js'
import { EntityListItem } from './entity-list-item.js'

export interface EntityListItemByIdProps extends Omit<EntityListItemProps, 'element'> {
  entityId?: string
  navigateOnClick?: boolean
}

export const EntityListItemById: FC<EntityListItemByIdProps> = props => {
  const { entityId, navigateOnClick = true, onClick, ...restProps } = props
  const navigateToEntity = useNavigateToEntity()
  const { entity } = graphHooks.useEntity(entityId)
  const handleClick = (ev: MouseEvent<HTMLLIElement>) => {
    if (navigateOnClick) {
      ev.preventDefault()
      navigateToEntity(entity)
    } else if (onClick) {
      onClick(ev)
    }
  }

  return entity ? <EntityListItem {...restProps} element={entity} onClick={handleClick} /> : null
}

export default EntityListItemById
