import { Box, Step, StepConnector, StepContent, StepLabel, Stepper, Typography } from '@mui/material'
import { dayjs } from '@tunasong/models'
import type { FeedItem } from '@tunasong/models'
import cn from 'classnames'
import type { FC } from 'react'
import { UserAvatar } from '../profile/index.js'
import { makeStyles } from '../styles.js'
import { useScroll } from './scroll.js'
import { graphHooks } from '@tunasong/graph-lib/react'

const useStyles = makeStyles()(theme => ({
  root: {
    overflow: 'auto',
  },
  avatar: {
    // left: -theme.spacing(),
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  stepper: {
    padding: 0,
    paddingLeft: theme.spacing(0.5),
    backgroundColor: 'inherit',
  },
  stepConnector: {
    minHeight: theme.spacing(),
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    fontSize: 12,
  },
  highlight: {
    borderColor: theme.vars.palette.success.light,
    borderStyle: 'dashed',
    borderWidth: 2,
  },
}))

export interface FeedProps {
  className?: string
  title?: string
  items: FeedItem[]
  maxItems?: number
  topRef?: React.RefCallback<HTMLDivElement>
}

export const Feed: FC<FeedProps> = props => {
  const { className, topRef, title, maxItems = Number.MAX_SAFE_INTEGER, items = [] } = props
  const { classes } = useStyles()

  const userIds = items.map(i => i.userId)
  const { getName } = graphHooks.useProfiles({ userIds })

  const showItems = items.slice(0, maxItems)

  const { highlightId } = useScroll(items)

  return (
    <div className={cn(className, classes.root)}>
      {title ? <Typography variant="subtitle1">{title}</Typography> : null}

      {showItems.length > 0 ? (
        <Stepper
          className={classes.stepper}
          orientation="vertical"
          connector={<StepConnector classes={{ lineVertical: classes.stepConnector }} />}
        >
          {showItems.map((item, idx) => (
            <Step
              key={idx}
              ref={idx === 0 ? topRef : null}
              // ref={item.id === activeId ? focusRef : null}
              active={true}
              className={cn({ [classes.highlight]: Boolean(item.id && item.id === highlightId) })}
            >
              <StepLabel
                slots={{
                  stepIcon: () => <UserAvatar className={classes.avatar} userId={item.userId} size="small" />,
                }}
              >
                <Box className={classes.label}>
                  <Typography variant="body2">{getName(item.userId)}</Typography>
                  <Typography variant="caption">{dayjs(item.createdAt).fromNow()}</Typography>
                </Box>
              </StepLabel>
              <StepContent>
                <Box className={classes.content}>{item.content}</Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      ) : (
        <Typography variant="caption">Messages will appear here - use the Chat box below</Typography>
      )}
    </div>
  )
}

export default Feed
