import type { BoxProps } from '@mui/material'
import { Box } from '@mui/material'
import { graphHooks } from '@tunasong/graph-lib/react'
import { ErrorOutline, Launch, Link as LinkIcon } from '@tunasong/icons'
import { getPlugin, usePlugins } from '@tunasong/plugin-lib'
import type { Entity, Persisted } from '@tunasong/schemas'
import type { FC } from 'react'
import { useState } from 'react'
import { CMD, SHIFT } from '../hotkeys/shortcut.js'
import { useNavigateToEntity } from '../navigation/navigate.js'
import { usePreloadOnHover } from './preload-on-hover.js'

export interface EntityLinkProps extends BoxProps {
  entity: Pick<Persisted<Entity>, 'id' | 'name' | 'type'>
  error?: boolean
  selected?: boolean
}

export const EntityLink: FC<EntityLinkProps> = props => {
  const { entity, error, ...restProps } = props

  const name = entity?.name ?? 'Untitled'
  const id = entity?.id

  const [containerEl, setContainerEl] = useState<HTMLDivElement | null>(null)
  usePreloadOnHover({ entityId: id, containerEl })

  const navigateToEntity = useNavigateToEntity()

  const handleClick = (ev: React.MouseEvent) => {
    if (!id) {
      return
    }
    ev.preventDefault()
    ev.stopPropagation()

    // Navigate may trigger compute intensive stuff, so allow e.g., animations to finish
    setTimeout(() => {
      // Check if the mouse click is a middle-button click
      if (ev.button === 1) {
        return navigateToEntity(entity, { navigationMode: 'external' })
      }
      if (ev.ctrlKey || ev.metaKey) {
        return navigateToEntity(entity, { navigationMode: 'external' })
      }
      if (ev.metaKey && ev.shiftKey) {
        return navigateToEntity(entity, { navigationMode: 'drawer' })
      }
      if (ev.shiftKey) {
        return navigateToEntity(entity, { navigationMode: 'dialog' })
      }
      return navigateToEntity(entity, { navigationMode: 'page' })
    }, 0)
  }

  const plugins = usePlugins('all')
  const LinkComponent = getPlugin(entity, plugins)?.components?.EntityLink

  const plugin = getPlugin(entity, plugins)
  const Icon = error ? ErrorOutline : plugin?.icon ?? LinkIcon
  const color = error ? 'red' : plugin?.color
  const title = `Click to open page, ${CMD} Click to open panel, ${CMD}+${SHIFT} Click to open drawer`

  return (
    <Box
      {...restProps}
      sx={{
        ...restProps.sx,
        gap: 0.5,
        userSelect: 'none',
        display: 'flex',
        alignItems: 'baseline',
      }}
      ref={setContainerEl}
    >
      {LinkComponent ? <LinkComponent entity={entity} /> : <></>}
      <Box
        title={title}
        onClick={handleClick}
        sx={{
          cursor: 'pointer',
          alignItems: 'baseline',
          color: theme => theme.vars.palette.text.secondary,
          '&:hover': {
            color: theme => (error ? theme.vars.palette.error.main : theme.vars.palette.primary.main),
          },
          display: 'inline-flex',
          userSelect: 'none',
          tabIndex: -1,
        }}
      >
        <Icon sx={{ color, alignSelf: 'end' }} />
        <Box sx={{ ml: 0.5, mr: 0.5 }} component="span">
          {name}
        </Box>
        <Launch sx={{ alignSelf: 'end', transform: 'scale(0.7)' }} />
      </Box>
    </Box>
  )
}

export const EntityLinkById = (props: Omit<EntityLinkProps, 'entity'> & { entityId: string }) => {
  const { entityId, ...restProps } = props
  const { entity } = graphHooks.useEntity(entityId)
  return entity ? <EntityLink {...restProps} entity={entity} /> : null
}
