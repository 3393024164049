import { graphHooks } from '@tunasong/graph-lib/react'
import type { Entity, Persisted } from '@tunasong/schemas'
import { entityPath } from '../entity/entity-path.js'

/** Generate the parent chain for an entity */
export const useParentChain = (entity?: Persisted<Entity>) => {
  const ancestors = graphHooks.useAncestors(entity)

  const parentChain = !(entity && ancestors) ? [] : entityPath(entity, ancestors, { hideSysEntities: true })

  return parentChain
}
