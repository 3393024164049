import { graphHooks } from '@tunasong/graph-lib/react'
import type { Entity, Persisted } from '@tunasong/schemas'
import { useUserConfig } from '../config/config-service.hook.js'

export const useFavorites = () => {
  const [favoriteIds, setFavorites] = useUserConfig('favorites')
  const { entities: favorites } = graphHooks.useEntitiesById(favoriteIds)

  const addFavorite = (favorite: Persisted<Entity>) => {
    setFavorites([...(favoriteIds ?? []), favorite.id])
  }

  const removeFavorite = (favorite: Persisted<Entity>) => {
    setFavorites((favoriteIds ?? []).filter(id => id !== favorite.id))
  }

  const isFavorite = (entity?: Persisted<Entity>) => entity && (favorites ?? []).some(f => f.id === entity.id)

  return { favorites, addFavorite, removeFavorite, isFavorite }
}
