import { Alert } from '@mui/material'
import { graphHooks } from '@tunasong/graph-lib/react'
import { SystemTags } from '@tunasong/models'
import type { Entity, Persisted } from '@tunasong/schemas'
import type { FC } from 'react'

export interface TemplateHeaderProps {
  entity?: Persisted<Entity>
}

export const TemplateHeader: FC<TemplateHeaderProps> = props => {
  const { entity } = props
  const { hasTag } = graphHooks.useTags(entity)

  if (!hasTag(SystemTags.Template)) {
    return null
  }

  return <Alert severity="info">This {entity?.type} is a template. </Alert>
}
