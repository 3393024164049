import { graphHooks } from '@tunasong/graph-lib/react'
import { logger } from '@tunasong/models'
import type { Entity, Persisted } from '@tunasong/schemas'
import type { ChangeEvent } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import type { FileWithPath } from 'react-dropzone'
import invariant from 'tiny-invariant'
import { getUniqueFolders, splitFolderPath } from '../entity/path-util.js'
import { useUploadStoredEntity } from './upload.js'
import { useStore } from '@tunasong/redux'

interface UploadFiles {
  /** If set, do not inherit the ACLs of the parent. @default false */
  isPrivate?: boolean
  /** Set the following tags to all uploaded entities */
  tags?: string[]
  onUploaded?: (entity: Persisted<Entity>, source?: File) => void | ((entity: Persisted<Entity>) => void)
  onComplete?(entities: Persisted<Entity>[]): void
}
export const useUploadFiles = ({ isPrivate = false, tags, onUploaded, onComplete }: UploadFiles = {}) => {
  const [scheduled, setScheduled] = useState(0)
  const [remaining, setRemaining] = useState(0)
  const uploaded = useRef<Persisted<Entity>[]>([])
  const { uploadNewEntity } = useUploadStoredEntity()
  const { createEntity } = graphHooks.useEntityCreate()
  const { entities } = graphHooks.useEntities()
  const [loadChildEntitiesPartial] = graphHooks.useLoadChildEntitiesPartial()

  const getFolder = useCallback(
    async ({ parentId, folderName }: { folderName: string; parentId: string | null }) => {
      const children = parentId
        ? (await loadChildEntitiesPartial({ parentId, type: 'folder' }).unwrap()).entities ?? []
        : entities

      const foundFolder = children.find(e => e.type === 'folder' && e.name === folderName && !e.trash)

      return foundFolder
    },
    [entities, loadChildEntitiesPartial]
  )

  const [loadEntity] = graphHooks.useLoadEntity()

  /** Returns a Record of <path, parentId> */
  const getOrCreateFolders = useCallback(
    async ({
      folder,
      parentId,
      folderMap,
    }: {
      folder: string
      parentId: string | null
      folderMap: Record<string, string | null>
    }) => {
      const pathMap: Record<string, string> = {}
      let curParentId = parentId
      let curPath: string | null = null

      for (const folderSegment of folder.split('/')) {
        curPath = curPath ? `${curPath}/${folderSegment}` : folderSegment

        /** If we already have created a folder, skip it */
        if (folderMap[curPath]) {
          curParentId = folderMap[curPath]
          continue
        }

        /** We need to check whether we have the folder. parentId === null means root which is OK  */
        let folder = await getFolder({ folderName: folderSegment, parentId: curParentId })

        /** If folder does not exist, create it */
        if (!folder) {
          const parent = curParentId ? await loadEntity({ id: curParentId }).unwrap() : null

          folder = await createEntity({
            parent,
            entity: { parentId: parent?.id, type: 'folder', name: folderSegment },
          })
        }
        curParentId = folder.id
        pathMap[curPath] = curParentId
      }
      return pathMap
    },
    [createEntity, getFolder, loadEntity]
  )

  /** The caller must ensure that the  */
  const { getState } = useStore()
  const uploadFile = useCallback(
    async ({ file, parentId }: { file: FileWithPath; parentId: string | null }) => {
      logger.debug('Attempting upload of file', file.name)
      if (typeof parentId === 'undefined') {
        throw new Error(`parentId must be null or string to upload files`)
      }

      // get the extension from the file name
      const ext = `.${file.name.split('.').pop()}`
      const type = file.type ? file.type : getState().storage.extensionToEntityType[ext ?? '']
      if (!type) {
        logger.error(`Unable to determine type for file ${file.name}`)
        throw new Error(`Unable to determine type for file ${file.name}`)
      }

      return new Promise<void>((resolve, reject) => {
        const reader = new FileReader()
        reader.addEventListener('load', async () => {
          try {
            const readerResult = reader.result
            if (!readerResult || typeof readerResult === 'string') {
              setRemaining(remaining => remaining - 1)
              return reject('Unable to read file')
            }

            const blob = new Blob([readerResult], { type })
            const uploadedObject = await uploadNewEntity(file.name, parentId, blob, isPrivate, tags)

            if (uploadedObject) {
              uploaded.current.push(uploadedObject)

              if (onUploaded) {
                onUploaded(uploadedObject, file)
              }
            }
            setRemaining(remaining => remaining - 1)
            resolve()
          } catch (e) {
            logger.error(`Error uploading ${file.name}`, e)
            setRemaining(remaining => remaining - 1)
            reject(e)
          }
        })
        reader.readAsArrayBuffer(file)
      })
    },
    [getState, isPrivate, onUploaded, tags, uploadNewEntity]
  )
  /** Trigger callbacks when uploaded changes */
  useEffect(() => {
    if (remaining === 0 && onComplete) {
      onComplete(uploaded.current)
    }
  }, [onComplete, remaining])

  const uploadFiles = useCallback(
    async ({ parentId, files }: { files: File[] | FileList; parentId: string | null }) => {
      if (!files) {
        return
      }
      if (parentId === undefined) {
        throw new Error(`parentId must be null or string to upload files`)
      }
      const filesArray: FileWithPath[] = Array.from(files)

      /** Ensure all the folders are available */
      const folders = getUniqueFolders(filesArray)

      let folderMap: Record<string, string | null> = {}
      if (parentId || parentId === null) {
        folderMap[''] = parentId
      }
      for (const folder of folders) {
        const m = await getOrCreateFolders({ folder, parentId, folderMap })
        folderMap = { ...folderMap, ...m }
      }

      const uploadPromises: Promise<void>[] = []
      for (const file of filesArray) {
        const { folder } = splitFolderPath(file)
        const parentId = folderMap[folder]
        invariant(parentId === null || Boolean(parentId), `parentId for folder ${folder} is not found`)

        /** Initialize internal state */
        setScheduled(scheduled => scheduled + 1)
        setRemaining(remaining => remaining + 1)

        uploadPromises.push(uploadFile({ file, parentId }))
      }
      return Promise.all(uploadPromises).catch(e => {
        logger.error(`Error uploading files`, e)
        throw e
      })
    },
    [getOrCreateFolders, uploadFile]
  )

  const handleChange = useCallback(
    (parentId: string | null) => async (ev: ChangeEvent<HTMLInputElement>) => {
      const files = ev.target.files
      if (!files) {
        return
      }
      await uploadFiles({ files, parentId })
      ev.target.files = null
    },
    [uploadFiles]
  )

  return {
    uploadFiles,
    /** handleChange from InputElement to upload directly */
    handleChange,
    uploading: remaining > 0,
    scheduled,
    remaining,
    progress: Math.round(((scheduled - remaining) * 100) / scheduled),
  }
}
