import { Box, LinearProgress } from '@mui/material'
import { graphHooks } from '@tunasong/graph-lib/react'
import { defaultSortOptions } from '@tunasong/models'
import type { FC } from 'react'
import { EntityLink } from '../entity/entity-link.js'
import { useEntitySort } from '../hooks/sort.js'
import { HBox } from '../layout/horizontal-box.js'
import EmptyLinks from './empty-links.js'

export interface BacklinksProps {
  id?: string
}

export const Backlinks: FC<BacklinksProps> = ({ id = '' }) => {
  const { backlinks = [] } = graphHooks.useEntityBacklinks({ entityId: id })

  const ids = backlinks
    .map(backlink => backlink.source)
    .filter(id => id !== 'ROOT')
    .filter(Boolean)

  const { entities: linkedEntities = [], isLoading } = graphHooks.useEntitiesById(ids)
  const entities = useEntitySort(linkedEntities, defaultSortOptions)

  return (
    <Box>
      {isLoading ? <LinearProgress /> : null}

      {entities.map(entity => {
        const backlink = backlinks.find(backlink => backlink.source === entity.id)
        return entity ? (
          <HBox key={entity.id} sx={{ justifyContent: 'space-between' }}>
            <EntityLink entity={entity} />
            <Box>[{backlink?.relation}]</Box>
          </HBox>
        ) : null
      })}
      {backlinks.length === 0 ? <EmptyLinks text="No backlinks" /> : null}
    </Box>
  )
}

export default Backlinks
